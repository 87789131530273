import {useQuery} from "@tanstack/react-query";
import {fetchFractionData} from "@/api/fractionDataFetcher";
import ModalImage from "react-modal-image";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {EthPrice} from "@/components/Perps/EthPrice";
import React, {useState} from "react";
import classNames from "classnames";
import {FractionalNFT} from "@/components/Fractions/types";
import {FaLock, FaUnlock} from "react-icons/fa";
import ReactSlider from "react-slider";
import {LiaBroomSolid} from "react-icons/lia";
import {SlippageButton} from "@/components/Perps/slippage/SlippageButton";
import {FNFTBuyButton} from "@/components/Fractions/FNFTBuyButton";
import {GridStyle} from "@/pages/collect";
import {useStringQueryParam} from "@/hooks/useQueryParam";

export const FractionTokenListings = ({tokenAddress, gridStyle}: {tokenAddress: string, gridStyle: GridStyle}) => {
  const [sweepAmount, setSweepAmount] = React.useState<number>(0);

  const {data, isLoading, isSuccess} = useQuery({
    queryKey: ['fractional_listings_', tokenAddress],
    queryFn: async () => fetchFractionData(tokenAddress),
    gcTime: 20 * 1000, // 20 seconds
    refetchInterval: 20 * 1000,
  });

  const renderGridItem = (item: FractionalNFT, i: number) => {
    const justUnlocked = i === sweepAmount;
    const isLocked = i > sweepAmount;
    let imageUrl = item.metadata.image;
    if (imageUrl.startsWith("ipfs://")) {
      imageUrl = `https://ipfs.io/ipfs/${imageUrl.replace("ipfs://", "")}`;
    }
    return (
      <div
        onClick={e => {
          e.stopPropagation();
          if (sweepAmount === i + 1) {
            setSweepAmount(i);
          } else {
            setSweepAmount(i + 1);
          }
        }}
        className={classNames("cursor-pointer standard-frame flex flex-col items-center justify-center rounded-md overflow-hidden", {
          "hover:border-neutral-content": i >= sweepAmount,
          "!border-call": i < sweepAmount,
        })}
        key={item.tokenId.toString()}
      >
        <div className="relative">
          { justUnlocked && <div className="absolute top-0 right-0 m-2 text-call"><FaUnlock size={14} /></div> }
          { isLocked &&
            <div className="absolute top-0 right-0 h-full w-full flex items-center justify-center bg-base-100/70 text-sm">
              Sweep to unlock
            </div>
          }
          { isLocked && <div className="absolute top-0 right-0 m-2 text-white"><FaLock size={14} /></div> }
          <img src={imageUrl} alt={`${tokenAddress}_${item.tokenId}`}/>
        </div>
        <div className="standard-stack w-full p-2">
          <div className="text-neutral-content text-xs w-full text-left">{item.metadata.name || `# ${item.tokenId}`}</div>
          <hr className="border-neutral-content/20 w-full"/>
          <SplitLabelValue label="Price" className="text-white text-sm" labelClassName="text-neutral-content">
            <EthPrice value={item.price} />
          </SplitLabelValue>
        </div>
      </div>
    );
  };

  const renderSweep = () => {
      return (
        <div className="flex flex-row items-center gap-2 p-1">
          <LiaBroomSolid size={36} />
          <input type="number"
                 value={`${sweepAmount}`}
                 onChange={e => {
                   const max = data?.items.length || 1;
                   setSweepAmount(Math.min(parseInt(e.target.value) || 0, max));
                 }}
                 className="unstyled-input max-w-[20px] text-white text-center"/>
          <ReactSlider
            className="flex items-center justify-center w-full min-w-[100px] max-w-[200px] border-l"
            thumbClassName="customSlider-thumb-sm !z-0"
            trackClassName="customSlider-track"
            min={0}
            step={1}
            max={data?.items.length || 1}
            value={sweepAmount}
            onChange={setSweepAmount}
          />
        </div>
      );
  }

  return (
    <>
      <div className={`p-6 flex-grow w-full grid gap-2 overflow-y-auto auto-rows-min no-scrollbar ${gridStyle.toString()}`}>
        {
            isLoading &&
            Array.from(Array(12).keys()).map(i => (
              <div key={`loading_item_${i}`} className="bg-slate-600 animate-pulse rounded-md min-h-[270px]">
              </div>
            ))
          }
          { isSuccess && data && data.items.map(renderGridItem) }
      </div>
      <div className="w-full p-4 responsive-flex items-center md:justify-between gap-2 min-h-[100px] md:min-h-[60px]">
        <FNFTBuyButton items={data?.items || []} sweepAmount={sweepAmount} tokenAddress={tokenAddress} />
        <div className="w-full md:w-max flex flex-row items-center justify-between md:justify-end gap-2">
          <SlippageButton />
          {renderSweep()}
        </div>
      </div>
    </>
  )
}
